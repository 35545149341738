<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useOffersStore } from '~/stores/account/offers.store';
import OfferCard from './partial/card.vue'

const route = useRoute();
const projectsStore = useOffersStore();

const offers = computed(() => projectsStore.offers)

</script>

<template>
	<div class="flex mt-3 relative">
		<div class="flex flex-col w-full gap-3">
			<div v-if="offers && offers.length" v-for="(offer, i) in offers" :key="i" class="bg-white p-2 rounded-md">
				<OfferCard :offer="offer" />
			</div>
		</div>
	</div>
	
</template>